import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Typography, Divider, Avatar, Box, ListItemIcon } from "@mui/material";
import { useAuth0 } from '@auth0/auth0-react';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';

export default function UserProfileMenu() {
    const [anchorEl, setAnchorEl] = useState(null);
    const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box>
            {isAuthenticated ? (
                <>
                    <IconButton
                        onClick={handleMenu}
                        size="large"
                        edge="end"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        color="inherit"
                    >
                        <Avatar src={user.picture} alt={user.name} />
                    </IconButton>
                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>
                            <Typography textAlign="center">{user.name}</Typography>
                        </MenuItem>
                        <Divider />
                        <MenuItem onClick={() => {
                            handleClose();
                            logout({ returnTo: window.location.origin });
                        }}>
                            <ListItemIcon>
                                <LogoutIcon fontSize="small" />
                            </ListItemIcon>
                            Log out
                        </MenuItem>
                    </Menu>
                </>
            ) : (
                <IconButton
                    size="large"
                    edge="end"
                    onClick={() => loginWithRedirect()}
                    color="inherit"
                >
                    <LoginIcon />
                </IconButton>
            )}
        </Box>
    );
}
