import * as React from 'react';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PageTemplate from "../../common/pageTemplate";
import {Link, List, ListItem, ListItemIcon, ListItemText, Paper, TextField} from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {styled} from '@mui/material/styles';
import toast from "react-hot-toast";
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import {FileDownload} from "@mui/icons-material";
import {BaseCard} from "../../common/baseCard";

const steps = ['Upload epitope FASTA file', 'Upload protein sequence FASTA file', 'Set identity threshold'];

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function FileUploader({label, setFileContent}) {

    const handleFileSelected = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            setFileContent(event.target.result);
        }

        reader.readAsText(file);
    }

    return (<Paper
        sx={{marginTop: "20px", padding: "20px"}}
        elevation={2}
    >
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
        >
            <Typography marginBottom="20px">{label}</Typography>
            <Button component="label" variant="contained" color="primary" startIcon={<CloudUploadIcon/>}>
                Upload
                <VisuallyHiddenInput type="file" onChange={handleFileSelected}/>
            </Button>
        </Box>
    </Paper>);
}


export default function Conservancy() {
    const apiService = new ApiService();
    const auth0Context = useAuth0();
    const [activeStep, setActiveStep] = useState(0);
    const [epitopeFileContent, setEpitopeFileContent] = useState("");
    const [protSeqFileContent, setProtSeqFileContent] = useState("");
    const [identityThreshold, setIdentityThreshold] = useState();
    const [response, setResponse] = useState()

    useEffect(() => {
        if (activeStep === steps.length) {
            toast.promise(apiService.conservancy(auth0Context, epitopeFileContent, protSeqFileContent, identityThreshold), {
                loading: "Analyzing...",
                error: (e) => {
                    return `An unexpected error occurred. Please report: ${e}`
                },
                success: (resp) => setResponse(resp.data)
            });
        }
    }, [activeStep]);

    const offerFileToDownload = (fileName, fileData) => {
        console.debug("Filename: ", fileName, " content: ", fileData);
        const blob = new Blob([atob(fileData)], {type: 'text/plain'})
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute("download", fileName);
        link.click();
        URL.revokeObjectURL(downloadUrl);
    }

    useEffect(() => {
        console.debug(response);
    }, [response]);

    const okToMove = () => {
        switch (activeStep) {
            case 0:
                return Boolean(epitopeFileContent);
            case 1:
                return Boolean(protSeqFileContent);
            case 2:
                return Boolean(identityThreshold);
            default:
                return false;
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setEpitopeFileContent("");
        setProtSeqFileContent("");
        setIdentityThreshold(undefined);
        setResponse(undefined);
        setActiveStep(0);
    };

    const stepResolver = (activeStep) => {
        switch (activeStep) {
            case 0:
                return <FileUploader label={steps[activeStep]} setFileContent={setEpitopeFileContent}
                                     stepIncrementer={handleNext}/>
            case 1:
                return <FileUploader label={steps[activeStep]} setFileContent={setProtSeqFileContent}
                                     stepIncrementer={handleNext}/>
            case 2:
                return identityThresholdReader;
            default:
                return <Typography sx={{mt: 2, mb: 1}}>Step {activeStep + 1}</Typography>
        }
    }

    const handleIdentyThresholdChange = (event) => {
        const value = event.target.value;
        setIdentityThreshold(value);
    }

    const identityThresholdReader = (
        <BaseCard
            sx={{
                minWidth: "450px",
            }}
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    '& .MuiTextField-root': {margin: 1, width: '100%'}, // make TextField full width
                    display: 'flex',
                    flexDirection: 'column', // stack children vertically
                }}
            >
                <TextField
                    label="Sequence identity threshold:"
                    helperText="Select the identity threshold at which you want to calculate epitope conservancy."
                    onChange={handleIdentyThresholdChange}
                />
            </Box>
        </BaseCard>
    );

    const downloadSection = (
        <Paper
            sx={{
            marginTop: "20px",
            padding: "20px",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
                 elevation={2}
        >
            <List>
                <ListItem>
                    <ListItemIcon><FileDownload /></ListItemIcon>
                    <ListItemText>
                        <Link onClick={() => offerFileToDownload("result.csv", response.csv)}>
                            result.csv
                        </Link>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <ListItemIcon><FileDownload /></ListItemIcon>
                    <ListItemText>
                        <Link onClick={() => offerFileToDownload("alignment.txt", response.alignment)}>
                            alignment.txt
                        </Link>
                    </ListItemText>
                </ListItem>
            </List>
        </Paper>
    )

    const pageContent = (<Box sx={{width: '100%', marginTop: '50px'}}>
        <Stepper activeStep={activeStep}>
            {steps.map((stepDescriptor, index) => {
                const stepProps = {};
                const labelProps = {};
                return (<Step key={stepDescriptor} {...stepProps}>
                    <StepLabel {...labelProps}>{stepDescriptor}</StepLabel>
                </Step>);
            })}
        </Stepper>
        {activeStep === steps.length ? (<>
            {Boolean(response) ? downloadSection : null}
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Box sx={{flex: '1 1 auto'}}/>
                <Button onClick={handleReset}>Reset</Button>
            </Box>
        </>) : (<>
            {stepResolver(activeStep)}
            <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{mr: 1}}
                >
                    Back
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>

                <Button onClick={handleNext} variant="contained" color="primary" disabled={!okToMove()}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
            </Box>
        </>)}
    </Box>);
    return (<PageTemplate content={pageContent}/>)
}