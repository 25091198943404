import React, {useEffect} from 'react';
import './App.css';
import {useAuth0} from "@auth0/auth0-react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import MainPage from "./pages/main/main_page/mainPage";
import RandomShuffler from "./pages/main/randomShuffler";
import SequenceMutator from "./pages/main/sequenceMutator";
import PositionalScan from "./pages/main/positionalScan";
import Conservancy from "./pages/main/conservancy";
import {PCPNavigator, PCPUploader} from "./pages/pcp";

function App() {
    const {isAuthenticated, loginWithRedirect, isLoading} = useAuth0();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <Router>
            <Routes>
                <Route path="/" element={<MainPage/>}></Route>
                <Route path="/random_shuffler" element={<RandomShuffler />}></Route>
                <Route path="/sequence_mutator" element={<SequenceMutator />}></Route>
                <Route path="/positional_scan" element={<PositionalScan />}></Route>
                <Route path="/conservancy" element={<Conservancy />}></Route>
                <Route path="/pcp/uploader" element={<PCPUploader />}></Route>
                <Route path="/pcp/navigator" element={<PCPNavigator />}></Route>
            </Routes>
        </Router>
    );
}

export default App;
