import PageTemplate from "../../common/pageTemplate";
import {Box, TextField} from "@mui/material";
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import {LoadingButton} from '@mui/lab';
import toast from "react-hot-toast";
import {BaseCard} from "../../common/baseCard";

export default function SequenceMutator() {
    const apiService = new ApiService();
    const auth0Context = useAuth0();
    const [inputSequence, setInputSequence] = useState('');
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = () => {
        const sequence = inputSequence?.trim()
        if (!sequence) {
            setError(true);
            return;
        }
        setIsLoading(true);
        toast.promise(apiService.sequenceMutator(auth0Context, sequence), {
            loading: "Mutating...",
            error: () => {
                setIsLoading(false);
                return "An unexpected error occurred. Please report"
            },
            success: (response) => {
                const fileData = response.data;
                const blob = new Blob([fileData], {type: 'text/plain'})
                const downloadUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute("download", "alanine_mutated.fasta");
                link.click();
                URL.revokeObjectURL(downloadUrl);
                setIsLoading(false);
            }
        });
    }

    const handleInputChange = (event) => {
        setInputSequence(event.target.value);
        if (error) setError(false);
    };

    const pageContent = (
        <BaseCard sx={{
            minWidth: "450px",
        }}
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    '& .MuiTextField-root': {width: '100%'},
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <TextField
                    error={error}
                    id="outlined-multiline-static"
                    label="Input sequence"
                    multiline
                    rows={4}
                    value={inputSequence}
                    onChange={handleInputChange}
                    helperText={error ? "This field can't be empty." : 'Paste a protein sequence here to generate alanine mutations.'}
                    variant="outlined"
                />
                <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleSubmit}>
                    Mutate
                </LoadingButton>
            </Box>
        </BaseCard>
    );
    return (
        <PageTemplate
            content={pageContent}
        />
    )
}