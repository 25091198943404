import React from 'react';
import {Box} from '@mui/material';
import MainAppBar from "./mainAppBar";
import MainMenu from "./menu/mainMenu";
import {Toaster} from "react-hot-toast";

const PageTemplate = ({content, sx}) => {

    return (
        <Box sx={{
            minHeight: "100vh",
            display: "flex",
        }}>
            <MainAppBar/>
            <MainMenu/>
            <Box sx={{
                margin: "65px auto auto auto",
                ...sx
            }}>
                {content}
            </Box>
            <Toaster/>
        </Box>
    );
};

export default PageTemplate;
