import {ListItem, ListItemButton, ListItemIcon, ListItemText, useTheme} from "@mui/material";
import {useLocation, useNavigate} from "react-router";

export default function MainMenuItem({path, icon, primaryText, secondaryText}) {
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();

    const menuItemTextColor = theme.palette.text.menuItems;

    const listItemButtonStyles = {
        color: 'white',
        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary,
            '& .MuiListItemIcon-root': {
                color: menuItemTextColor,
            },
            '& .MuiListItemText-primary': {
                color: menuItemTextColor,
            },
            '& .MuiListItemText-secondary': {
                color: menuItemTextColor,
            },
        },
    }

    return (
        <ListItem>
            <ListItemButton
                onClick={() => navigate(path)}
                selected={location.pathname === path}
                sx={listItemButtonStyles}
            >
                <ListItemIcon sx={{ color: menuItemTextColor }}>
                    {icon}
                </ListItemIcon>
                <ListItemText
                    primary={primaryText}
                    secondary={secondaryText}
                    primaryTypographyProps={{ style: { color: menuItemTextColor } }}
                    secondaryTypographyProps={{ style: { color: menuItemTextColor } }}
                />
            </ListItemButton>
        </ListItem>
    );
}