import {Box, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import PageTemplate from "../../common/pageTemplate";
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import toast from "react-hot-toast";
import {BaseCard} from "../../common/baseCard";

export default function PositionalScan() {
    const apiService = new ApiService();
    const auth0Context = useAuth0();
    const [inputSequence, setInputSequence] = useState('');
    const [isInputSequenceError, setIsInputSequenceError] = useState(false);
    const [position, setPosition] = useState();
    const [isPositionError, setIsPositionError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validatePosition = (position, inputSequence) => {
        if (position === '' || !/^[0-9]*$/.test(position)) {
            setIsPositionError(true);
            return false;
        }
        if (inputSequence && Number(position) > inputSequence.length) {
            setIsPositionError(true);
            return false;
        }
        setIsPositionError(false);
        return true
    }

    const validateInputSequence = (inputSequence) => {
        if (!inputSequence) {
            setIsInputSequenceError(true);
            return false;
        }
        setIsInputSequenceError(false);
        return true;
    }

    const validateAll = () => {
        return validatePosition(position, inputSequence) && validateInputSequence(inputSequence);
    }

    const handleInputChange = (event) => {
        const newValue = event.target.value;
        setInputSequence(newValue);
        validateInputSequence(newValue);
    };

    const handlePositionChange = (event) => {
        const newValue = event.target.value;
        setPosition(newValue);
        validatePosition(newValue);
    }

    const handleSubmit = () => {
        const sequence = inputSequence?.trim()
        if (!validateAll()) {
            return;
        }
        setIsLoading(true);
        toast.promise(apiService.positionalScan(auth0Context, sequence, Number(position)), {
            loading: "Scanning...",
            error: () => {
                setIsLoading(false);
                return "An unexpected error occurred. Please report"
            },
            success: (response) => {
                setIsLoading(false);
                const fileData = response.data;
                const blob = new Blob([fileData], {type: 'text/plain'})
                const downloadUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute("download", "result.fasta");
                link.click();
                URL.revokeObjectURL(downloadUrl);
            }
        });
    }

    const pageContent = (
        <BaseCard
            sx={{
                minWidth: "450px",
            }}
        >
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    '& .MuiTextField-root': {width: '100%'}, // make TextField full width
                    display: 'flex',
                    flexDirection: 'column', // stack children vertically
                }}
            >
                <TextField
                    id="outlined-multiline-static"
                    label="Input sequence"
                    multiline
                    rows={4}
                    value={inputSequence}
                    onChange={handleInputChange}
                    error={isInputSequenceError}
                    helperText={isInputSequenceError ? "This field can't be empty." : 'Paste a protein sequence here to perform the scan.'}
                    variant="outlined"
                />
                <TextField
                    id="position"
                    label="Position"
                    variant="outlined"
                    value={position}
                    onChange={handlePositionChange}
                    error={isPositionError}
                    helperText={isPositionError ? "Provide a valid number. Less than the length of the sequence" : 'A position to use in the scan.'}
                    margin="normal"
                />
                <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleSubmit}>
                    Scan
                </LoadingButton>
            </Box>
        </BaseCard>
    );
    return (
        <PageTemplate
            content={pageContent}
        />
    )
}