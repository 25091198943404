import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Box from "@mui/material/Box";
import * as React from "react";
import {styled} from "@mui/material/styles";
import PageTemplate from "../../common/pageTemplate";
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import toast from "react-hot-toast";
import {BaseCard} from "../../common/baseCard";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

export function PCPUploader() {
    const apiService = new ApiService();
    const auth0Context = useAuth0();

    const handleFileSelected = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (event) => {
            const ubiqueMatrixContent = event.target.result;
            const matrixName = file.name;
            toast.promise(apiService.pcpUploader(auth0Context, ubiqueMatrixContent, matrixName), {
                pending: 'Uploading ubique matrix',
                success: 'Ubique matrix uploaded. Changes will be reflexted in the PCP Navigator section once the pipeline completes',
                error: (e) => `Error uploading ubique matrix. Please report: ${e}`
            })
        }

        reader.readAsText(file);
    }

    const pageContent = <BaseCard
        sx={{
            minWidth: "450px",
        }}
    >
        <Box
            component="form"
            noValidate
            autoComplete="off"
            sx={{
                display: 'flex', flexDirection: 'column', alignItems: 'center',
            }}
        >
            <Typography marginBottom="20px">Upload ubique matrix</Typography>
            <Button component="label" variant="contained" color="primary" startIcon={<CloudUploadIcon/>}>
                Upload
                <VisuallyHiddenInput type="file" onChange={handleFileSelected}/>
            </Button>
        </Box>
    </BaseCard>

    return <PageTemplate content={pageContent}/>
}