import {Box, Typography} from "@mui/material";
import PageTemplate from "../../common/pageTemplate";
import {DataGrid,} from '@mui/x-data-grid';
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import {useEffect, useState} from "react";
import toast from "react-hot-toast";

const columnDef = [{
    field: "pep_name", headerName: "Pep Name"
}, {
    field: "prot_code", headerName: "Prot Code"
}, {
    field: "prot_name", headerName: "Prot Name"
}, {
    field: "strain", headerName: "Strain"
}, {
    field: "pep_name_full", headerName: "Pep Name Full"
}, {
    field: "sequence", headerName: "Sequence"
}, {
    field: "region", headerName: "Region"
}, {
    field: "residue", headerName: "Residue"
}, {
    field: "pep_index", headerName: "Pep Index"
}, {
    field: "bepipred-3.0 score", headerName: "BepiPred 3.0 Score"
}, {
    field: "length_pep", headerName: "Length Pep"
}, {
    field: "isoelectric_point", headerName: "Isoelectric Point"
}, {
    field: "hydrophobicity", headerName: "Hydrophobicity"
}, {
    field: "molecular_weight", headerName: "Molecular Weight"
}, {
    field: "charge_at_ph7", headerName: "Charge at pH7"
}, {
    field: "aromaticity", headerName: "Aromaticity"
}, {
    field: "instability_index", headerName: "Instability Index"
}, {
    field: "boman_index", headerName: "Boman Index"
}, {
    field: "helix", headerName: "Helix"
}, {
    field: "turn", headerName: "Turn"
}, {
    field: "sheet", headerName: "Sheet"
}, {
    field: "a", headerName: "A"
}, {
    field: "c", headerName: "C"
}, {
    field: "d", headerName: "D"
}, {
    field: "e", headerName: "E"
}, {
    field: "f", headerName: "F"
}, {
    field: "g", headerName: "G"
}, {
    field: "h", headerName: "H"
}, {
    field: "i", headerName: "I"
}, {
    field: "k", headerName: "K"
}, {
    field: "l", headerName: "L"
}, {
    field: "m", headerName: "M"
}, {
    field: "n", headerName: "N"
}, {
    field: "p", headerName: "P"
}, {
    field: "q", headerName: "Q"
}, {
    field: "r", headerName: "R"
}, {
    field: "s", headerName: "S"
}, {
    field: "t", headerName: "T"
}, {
    field: "v", headerName: "V"
}, {
    field: "w", headerName: "W"
}, {
    field: "y", headerName: "Y"
}, {
    field: "ml_score", headerName: "ML Score"
}, {
    field: "blast_score", headerName: "BLAST Score"
}, {
    field: "total_score", headerName: "Total Score"
}, {
    field: "prediction", headerName: "Prediction"
}, {
    field: "vaxigen", headerName: "Vaxigen"
}, {
    field: "pepsysco", headerName: "Pepsysco"
}, {
    field: "synthesis", headerName: "Synthesis"
}, {
    field: "pepscan", headerName: "PepScan"
}, {
    field: "acetyl", headerName: "Acetyl"
}, {
    field: "amide", headerName: "Amide"
}, {
    field: "chemiluminescence", headerName: "Chemiluminescence"
}, {
    field: "elisa_do", headerName: "ELISA DO"
}];

const columnVisibilityModel = {
    "ELISA_DO": false,
    "Chemiluminescence": false,
    "Amide": false,
    "Acetyl": false,
    "PepScan": false,
    "Synthesis": false,
    "Pepsysco": false,
    "Vaxigen": false,
}

export function PCPNavigator({}) {
    const apiService = new ApiService();
    const auth0Context = useAuth0();
    const [rows, setRows] = useState([]);

    useEffect(() => {
        toast.promise(apiService.pcpNavigator(auth0Context), {
            error: (e) => `Error retrieving data from API: ${e}`,
            success: response => setRows(response.data)
        })
    }, []);

    const pageContent = <Box
        sx={{
            padding: "20px",
        }}
    >
        <Box>
            <Typography variant="h4">PCP Data</Typography>
        </Box>
        <Box>
            <DataGrid
                sx={{
                    height: "800px",
                }}
                rows={rows}
                columns={columnDef}
                initialState={{
                    columns: {
                        columnVisibilityModel
                    }
                }}
            />
        </Box>

    </Box>

    return <PageTemplate
        sx={{
            overflow: "auto"
        }}
        content={pageContent}/>
}