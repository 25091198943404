import axios from 'axios'

const API_URL = 'https://acd02owhp1.execute-api.us-east-1.amazonaws.com/production'
// const API_URL = 'http://localhost:3001'
const AUTH0_AUDIENCE = 'https://api.ubique.bio'

class ApiService {
    constructor() {
        this.client = axios.create({
            baseURL: API_URL,
        });
    }

    async invokeWithToken(auth0Context, func) {
        return auth0Context.getAccessTokenSilently({audience: AUTH0_AUDIENCE}).then((token) => {
            const headers = {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            };
            return func({headers});
        });
    }

    async randomShuffler(auth0Context, expected_responses, original_sequence) {
        const request = {
            expected_responses: expected_responses,
            sequence: original_sequence
        }
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/random_shuffler`, request, config)
        });
    }

    async sequenceMutator(auth0Context, original_sequence) {
        const request = {
            sequence: original_sequence
        }
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/sequence_mutator`, request, config)
        });
    }

    async positionalScan(auth0Context, original_sequence, position) {
        const request = {
            sequence: original_sequence,
            position: position
        }
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/positional_scan`, request, config)
        });
    }

    async conservancy(auth0Context, epitopeFileContent, protSeqFileContent, identityThreshold) {
        const request = {
            epitopeFileContent: epitopeFileContent,
            protSeqFileContent: protSeqFileContent,
            identityThreshold: identityThreshold
        }
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/conservancy`, request, config)
        });
    }

    async pcpUploader(auth0Context, ubiqueMatrix, matrixName) {
        const request = {
            ubiqueMatrix: ubiqueMatrix,
            matrixName: matrixName
        }
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.post(`${API_URL}/pcp/uploader`, request, config)
        });
    }

    async pcpNavigator(auth0Context) {
        return this.invokeWithToken(auth0Context, (config) => {
            return axios.get(`${API_URL}/pcp/detailer`, config);
        });
    }
}

export default ApiService;