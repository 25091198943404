import PageTemplate from "../../common/pageTemplate";
import {Box, TextField} from "@mui/material";
import ApiService from "../../services/ApiService";
import {useAuth0} from "@auth0/auth0-react";
import {useState} from "react";
import {LoadingButton} from '@mui/lab';
import toast from "react-hot-toast";
import {BaseCard} from "../../common/baseCard";

export default function RandomShuffler() {
    const apiService = new ApiService();
    const auth0Context = useAuth0();
    const [inputSequence, setInputSequence] = useState('');
    const [sequenceError, setSequenceError] = useState(false);
    const [responseNumberError, setResponseNumberError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [numberOfResponses, setNumberOfResponses] = useState("");

    const handleNumberOfResponsesChange = (event) => {
        const newValue = event.target.value.replace(/[^0-9]/g, "");
        setNumberOfResponses(newValue);
        if (responseNumberError) setResponseNumberError(false);
    }

    const handleSubmit = () => {
        const nr = Number(numberOfResponses)
        if (isNaN(nr) || nr < 1) {
            setResponseNumberError(true);
            return;
        }
        const sequence = inputSequence?.trim()?.replace(/(\r\n|\n|\r)/g, "");
        if (!sequence) {
            setSequenceError(true);
            return;
        }
        setIsLoading(true);
        toast.promise(apiService.randomShuffler(auth0Context, nr, sequence), {
            loading: "Shuffling...",
            error: () => {
                setIsLoading(false);
                return "An unexpected sequenceError occurred. Please report"
            },
            success: (response) => {
                const fileData = response.data;
                const blob = new Blob([fileData], {type: 'text/plain'})
                const downloadUrl = URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute("download", `${numberOfResponses}_random_shuffled.fasta`);
                link.click();
                URL.revokeObjectURL(downloadUrl);
                setIsLoading(false);
            }
        });
    }

    const handleInputChange = (event) => {
        setInputSequence(event.target.value);
        if (sequenceError) setSequenceError(false);
    };

    const pageContent = (
        <BaseCard id="shuffler-card">
            <Box
                component="form"
                noValidate
                autoComplete="off"
                sx={{
                    '& .MuiTextField-root': {width: '100%'},
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: "450px"
                }}
            >
                <TextField id="number-of-response-input"
                           label="Number of responses"
                           value={numberOfResponses}
                           onChange={handleNumberOfResponsesChange}
                           type="text"
                           error={responseNumberError}
                           helperText={responseNumberError ? "This field can't be empty and greater than zero." : 'Enter the number of the expected responses'}
                           inputProps={{pattern: /^[0-9]+$/}}/>
                <TextField
                    error={sequenceError}
                    id="original-sequence-input"
                    label="Input original sequence"
                    multiline
                    rows={4}
                    value={inputSequence}
                    onChange={handleInputChange}
                    helperText={sequenceError ? "This field can't be empty." : 'Paste a protein sequence to be shuffled here.'}
                    variant="outlined"
                    margin="normal"
                />
                <LoadingButton loading={isLoading} variant="contained" color="primary" onClick={handleSubmit}>
                    Shuffle
                </LoadingButton>
            </Box>
        </BaseCard>
    );
    return (
        <PageTemplate
            content={pageContent}
        />
    )
}