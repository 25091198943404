import {AppBar, Toolbar, Typography, Box} from "@mui/material";
import React from "react";
import AppLogo from "./appLogo";
import UserProfileMenu from "./profile/userProfileMenu";


export default function MainAppBar() {

    return (
        <AppBar position="fixed" sx={{ zIndex: 1400, flexGrow: 1  }}>
            <Toolbar>
                <Typography variant="h6" noWrap>
                    <AppLogo />
                </Typography>
                <Box ml="auto">
                    <UserProfileMenu/>
                </Box>
            </Toolbar>
        </AppBar>
    );
}
