import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Auth0Provider} from "@auth0/auth0-react";
import {createTheme, ThemeProvider} from '@mui/material/styles';

export const themeOptions = {
    palette: {
        mode: 'light',
        primary: {
            main: '#72ACAD',
        },
        secondary: {
            main: '#E1EDED',
        },
        background: {
            default: '#F5F5F5',
            alternative: '#172222',
            paper: '#FFF',
        },
        text: {
            menuItems: '#F5F5F5'
        }
    },
};

const theme = createTheme(
    themeOptions,
);

const root = ReactDOM.createRoot(document.getElementById('root'));
const domain = "dev-vfgxxfax8g7j4iyh.us.auth0.com";
const clientId = "fAcsCQBXikLrrAEf8EwoBRao3l4ODdtr";
const audience = "https://api.ubique.bio";

root.render(
    <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
            audience: audience,
            redirect_uri: window.location.origin
        }}>
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </React.StrictMode>
    </Auth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
