import {Paper} from "@mui/material";

export function BaseCard({children, sx}) {
    return <Paper
        sx={{
            marginTop: "20px",
            padding: "20px",
            ...sx
        }}
        elevation={2}
    >
        {children}
    </Paper>
}