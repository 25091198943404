import {Collapse, Drawer, List, ListItemButton, ListItemText, Toolbar, useTheme} from "@mui/material";
import React, {useState} from "react";
import MainMenuItem from "./mainMenuItem";
import ShuffleIcon from '@mui/icons-material/Shuffle';
import FindReplaceIcon from '@mui/icons-material/FindReplace';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import DifferenceIcon from '@mui/icons-material/Difference';
import DriveFolderUploadRoundedIcon from '@mui/icons-material/DriveFolderUploadRounded';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import {ExpandLess, ExpandMore} from "@mui/icons-material";

export const drawerWidth = 240;

export default function MainMenu() {
    const theme = useTheme();
    const [synthesisOpen, setSynthesisOpen] = useState(true);
    const [optimizationOpen, setOptimizationOpen] = useState(true);
    const menuItemTextColor = theme.palette.text.menuItems
    const handleClickSynthesis = () => {
        setSynthesisOpen(!synthesisOpen);
    };

    const handleClickOptimization = () => {
        setOptimizationOpen(!optimizationOpen);
    };

    return (
        <Drawer
            elevation={2}
            color="secondary"
            variant="permanent"
            style={{
                width: drawerWidth,
                flexShrink: 0,
            }}
            PaperProps={{
                style: {
                    width: drawerWidth,
                    backgroundColor: theme.palette.background.alternative,
                },
            }}
        >
            <Toolbar/>
            <div style={{overflow: 'auto'}}>
                <List style={{position: 'relative'}}>
                    <ListItemButton onClick={handleClickSynthesis} sx={{color: menuItemTextColor}}>
                        <ListItemText primary="Prediction" sx={{'.MuiTypography-root': {color: menuItemTextColor}}}/>
                        {synthesisOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={synthesisOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MainMenuItem
                                icon={<DriveFolderUploadRoundedIcon />}
                                primaryText={'PCP trigger'}
                                path="/pcp/uploader"
                            />
                            <MainMenuItem
                                icon={<FindInPageIcon />}
                                primaryText={'PCP Navigator'}
                                path="/pcp/navigator"
                            />
                        </List>
                    </Collapse>
                    <ListItemButton onClick={handleClickOptimization} sx={{color: menuItemTextColor}}>
                        <ListItemText primary="Optimization" sx={{ '.MuiTypography-root': { color: menuItemTextColor } }} />
                        {optimizationOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={optimizationOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <MainMenuItem
                                icon={<ShuffleIcon />}
                                primaryText={'Shuffler'}
                                path="/random_shuffler"
                            />
                            <MainMenuItem
                                icon={<FindReplaceIcon/>}
                                primaryText={'Mutator'}
                                path="/sequence_mutator"
                            />
                            <MainMenuItem
                                icon={<DocumentScannerIcon/>}
                                primaryText={'Positional Scan'}
                                path="/positional_scan"
                            />
                        </List>
                        <MainMenuItem
                            icon={<DifferenceIcon/>}
                            primaryText={'Conservancy'}
                            path="/conservancy"
                        />
                    </Collapse>
                </List>

            </div>
        </Drawer>
    );
}